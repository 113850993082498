import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export type Base = (
  "year" | "years" | "y" |
  "month" | "months" | "M" |
  "week" | "weeks" | "w" |
  "day" | "days" | "d" |
  "hour" | "hours" | "h" |
  "minute" | "minutes" | "m" |
  "second" | "seconds" | "s" |
  "millisecond" | "milliseconds" | "ms"
);

type _quarter = "quarter" | "quarters" | "Q";
export type DurationConstructor = Base | _quarter;

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  constructor() { }

  /**
   * validates a date string with the given format
   * @param dateStr
   * @param dateFormat
   * @returns
   */
  validateDateStr(dateStr: string, dateFormat: string) {
    const now = new Date();

    if (dateStr?.substring === undefined) {
      return false;
    }

    const yr = dateFormat.indexOf('YYYY') >= 0 ? dateStr.substring(dateFormat.indexOf('YYYY'), dateFormat.indexOf('YYYY') + 4) : String(now.getFullYear());
    const mo = dateFormat.indexOf('MM') >= 0 ? dateStr.substring(dateFormat.indexOf('MM'), dateFormat.indexOf('MM') + 2) : _.padStart(String(now.getMonth() + 1), 2, '0');
    const da = dateFormat.indexOf('dd') >= 0 ? dateStr.substring(dateFormat.indexOf('dd'), dateFormat.indexOf('dd') + 2) : _.padStart(String(now.getDate()), 2, '0');
    const hr = dateFormat.indexOf('HH') >= 0 ? dateStr.substring(dateFormat.indexOf('HH'), dateFormat.indexOf('HH') + 2) : '00';
    const mi = dateFormat.indexOf('mm') >= 0 ? dateStr.substring(dateFormat.indexOf('mm'), dateFormat.indexOf('mm') + 2) : '00';
    const se = dateFormat.indexOf('ss') >= 0 ? dateStr.substring(dateFormat.indexOf('ss'), dateFormat.indexOf('ss') + 2) : '00';

    const d = new Date(Date.parse(yr + '-' + mo + '-' + da + ' ' + hr + ':' + mi + ':' + se));

    const newDateStr = dateFormat.replace('YYYY', String(d.getFullYear()))
      .replace('MM', _.padStart(String(d.getMonth() + 1), 2, '0'))
      .replace('dd', _.padStart(String(d.getDate()), 2, '0'))
      .replace('HH', _.padStart(String(d.getHours()), 2, '0'))
      .replace('mm', _.padStart(String(d.getMinutes()), 2, '0'))

    return dateStr === newDateStr;
  }



  /**
   * returns a Date object set to the start of a given unit (e.g. startOf('2022-02-16 08:00:00', 'month') = '2022-02-01 00:00:00'
   */
  getStartOf(unit: DurationConstructor, date = new Date()) {
    const myDate = new Date(Date.parse(date.toISOString()));

    switch (unit) {
      case "year":
      case "years":
      case "y":
        myDate.setMonth(0);
      // no break!
      case "quarter":
      case 'quarters':
        if (unit === 'quarter' || unit === 'quarters') {
          myDate.setMonth(myDate.getMonth() - (myDate.getMonth() % 3));
        }
      // no break!
      case "month":
      case "months":
      case "M":
        myDate.setDate(1);
      // no break!
      case "week":
      case "weeks":
      case "w":
        if (unit === 'week' || unit === 'weeks' || unit === 'w') {
          myDate.setDate(myDate.getDate() - myDate.getDay());
        }
      // no break!
      case "day":
      case "days":
      case "d":
        myDate.setHours(0);
        myDate.setMinutes(0);
        myDate.setSeconds(0);
        myDate.setMilliseconds(0);
        break;
      default:
        throw new Error('datetime.service: getStartOf() unsupported unit: ' + unit);
    }



    return myDate;

  }

  /**
   * returns a Date object set to the end of a given unit (e.g. endOf('2022-02-16 08:00:00', 'month') = '2022-02-28 23:59:59'
   */
  getEndOf(unit: DurationConstructor, date = new Date()) {
    const myDate = new Date(Date.parse(date.toISOString()));

    switch (unit) {
      case "year":
      case "years":
      case "y":
        myDate.setMonth(11);
      // no break!
      case "quarter":
      case 'quarters':
        if (unit === 'quarter' || unit === 'quarters') {
          if ((myDate.getMonth() + 1) % 3 !== 0) {
            myDate.setMonth((myDate.getMonth() + (3 - (myDate.getMonth() % 3))));
          }
        }
      // no break!
      case "month":
      case "months":
      case "M":
        if (unit !== 'quarter' && unit !== 'quarters') {
          myDate.setMonth(myDate.getMonth() + 1);
        }
        myDate.setDate(0);
      // no break!
      case "week":
      case "weeks":
      case "w":
        if (unit === 'week' || unit === 'weeks' || unit === 'w') {
          myDate.setDate(myDate.getDate() + (6 - myDate.getDay()));
        }
      // no break!
      case "day":
      case "days":
      case "d":
        myDate.setHours(23);
        myDate.setMinutes(59);
        myDate.setSeconds(59);
        myDate.setMilliseconds(999);
        break;
      default:
        throw new Error('datetime.service: getEndOf() unsupported unit: ' + unit);
    }



    return myDate;

  }

  /**
   * returns the difference between 2 dates in the given unit
   */
  getDiff(date1: Date, date2: Date, unit: Base) {
    let diff = date1.getTime() - date2.getTime(); // millliseconds

    switch (unit) {
      case "year":
      case "years":
      case "y":
        diff = diff / 1000 / 60 / 60 / 24 / 365;
        break;
      case "month":
      case "months":
      case "M":
        diff = diff / 1000 / 60 / 60 / 24 / 30;
        break;
      case "week":
      case "weeks":
      case "w":
        diff = diff / 1000 / 60 / 60 / 24 / 7;
        break;
      case "day":
      case "days":
      case "d":
        diff = diff / 1000 / 60 / 60 / 24;
        break;
      case "hour":
      case "hours":
      case "h":
        diff = diff / 1000 / 60 / 60;
        break;
      case "minute":
      case "minutes":
      case "m":
        diff = diff / 1000 / 60;
        break;
      case "second":
      case "seconds":
      case "s":
        diff = diff / 1000;
        break;
    }

    if (diff < 0) {
      return Math.ceil(diff);
    }

    return Math.floor(diff);
  }

  /**
   * Returns if date1 is after date2
   */
  isAfter(date1: Date, date2: Date) {
    return date1.getTime() > date2.getTime();
  }

  /**
   * Returns if date1 is before date2
   */
  isBefore(date1: Date, date2: Date) {
    return date1.getTime() < date2.getTime();
  }

  /**
   * Subtract a amount of time-unit from the given date, returning the new date
   */
  subtract(date: Date, amount: number, unit: DurationConstructor) {
    const myDate = new Date(Date.parse(date.toISOString()));

    switch (unit) {
      case "year":
      case "years":
      case "y":
        myDate.setFullYear(myDate.getFullYear() - amount);
        break;
      case "month":
      case "months":
      case "M":
        myDate.setMonth(myDate.getMonth() - amount);
        break;
      case "week":
      case "weeks":
      case "w":
        myDate.setDate(myDate.getDate() - (amount * 7));
        break;
      case "day":
      case "days":
      case "d":
        myDate.setDate(myDate.getDate() - amount);
        break;
      case "hour":
      case "hours":
      case "h":
        myDate.setHours(myDate.getHours() - amount);
        break;
      case "minute":
      case "minutes":
      case "m":
        myDate.setMinutes(myDate.getMinutes() - amount);
        break;
      case "second":
      case "seconds":
      case "s":
        myDate.setSeconds(myDate.getSeconds() - amount);
        break;
      case "millisecond":
      case "milliseconds":
      case "ms":
        myDate.setMilliseconds(myDate.getMilliseconds() - amount);
        break;
    }

    return myDate;
  }

  /**
   * Add a amount of time-unit from the given date, returning the new date
   */
  add(date: Date, amount: number, unit: DurationConstructor) {
    return this.subtract(date, amount * -1, unit);
  }

  /**
   * returns the relative time from now as string (e.g. "40 minutes ago" or "in 1 hour")
   * @param date
   * @returns
   */
  fromNow(date: Date, language = 'en') {
    const now = new Date();
    let dateA: Date;
    let dateB: Date;
    let str = '';


    if (now.getTime() > date.getTime()) {
      dateA = now;
      dateB = date;
      str += language === 'de' ? 'vor ' : '';

    } else {
      dateA = date;
      dateB = now;
      str += language === 'de' ? 'in ' : 'in ';
    }



    const diff = dateA.getTime() - dateB.getTime();
    if (diff > (1000 * 60 * 60 * 24 * 365)) {
      const yrs = Math.round(diff / 1000 / 60 / 60 / 24 / 365);
      str += String(yrs);
      str += language === 'de' ?
        yrs > 1 ? ' Jahren' : ' Jahr' :
        yrs > 1 ? ' years' : ' year';
    } else if (diff > (1000 * 60 * 60 * 24 * 30)) {
      const mths = Math.round(diff / 1000 / 60 / 60 / 24 / 30);
      str += String(mths);
      str += language === 'de' ?
        mths > 1 ? ' Monaten' : ' Monat' :
        mths > 1 ? ' months' : ' month';
    } else if (diff > (1000 * 60 * 60 * 24)) {
      const dys = Math.round(diff / 1000 / 60 / 60 / 24);
      str += String(dys);
      str += language === 'de' ?
        dys > 1 ? ' Tagen' : ' Tag' :
        dys > 1 ? ' days' : ' day';
    } else if (diff > (1000 * 60 * 60)) {
      const hrs = Math.round(diff / 1000 / 60 / 60);
      str += String(hrs);
      str += language === 'de' ?
        hrs > 1 ? ' Stunden' : ' Stunde' :
        hrs > 1 ? ' hours' : ' hour';
    } else if (diff > (1000 * 60)) {
      const mins = Math.round(diff / 1000 / 60);
      str += String(mins);
      str += language === 'de' ?
        mins > 1 ? ' Minuten' : ' Minute' :
        mins > 1 ? ' minutes' : ' minute';
    } else {
      const secs = Math.round(diff / 1000);
      str += String(secs);
      str += language === 'de' ?
        secs > 1 ? ' Sekunden' : ' Sekunde' :
        secs > 1 ? ' seconds' : ' second';
    }

    if (language !== 'de' && now.getTime() > date.getTime()) {
      str += ' ago';
    }

    return str;


  }
}
