
/**********************************************************************
 * Auto generated by CI pipline and scripts/ip_ranges_from_consul.js  *
 * At 2024-08-15T11:23:27.028Z                                        *
 * Do not manually change this file, as it will be overridden!!       *
 *********************************************************************/
export const ipConfig = {
    "45": {
        "12": {
            "48": {
                "subnet": "255.255.255.0",
                "network": "45.12.48.0",
                "bitmask": "24",
                "gateway": "45.12.48.254",
                "broadcast": "45.12.48.255"
            },
            "49": {
                "subnet": "255.255.255.0",
                "network": "45.12.49.0",
                "bitmask": "24",
                "gateway": "45.12.49.254",
                "broadcast": "45.12.49.255"
            },
            "50": {
                "subnet": "255.255.255.0",
                "network": "45.12.50.0",
                "bitmask": "24",
                "gateway": "45.12.50.254",
                "broadcast": "45.12.50.255"
            },
            "51": {
                "subnet": "255.255.255.0",
                "network": "45.12.51.0",
                "bitmask": "24",
                "gateway": "45.12.51.254",
                "broadcast": "45.12.51.255"
            }
        },
        "131": {
            "252": {
                "subnet": "255.255.255.0",
                "network": "45.131.252.0",
                "bitmask": "24",
                "gateway": "45.131.252.1",
                "broadcast": "45.131.252.255"
            },
            "253": {
                "subnet": "255.255.255.0",
                "network": "45.131.253.0",
                "bitmask": "24",
                "gateway": "45.131.253.1",
                "broadcast": "45.131.253.255"
            }
        },
        "133": {
            "191": {
                "subnet": "255.255.255.128",
                "network": "45.133.191.128",
                "bitmask": "25",
                "gateway": "45.133.191.254",
                "broadcast": "45.133.191.255"
            }
        },
        "144": {
            "132": {
                "subnet": "255.255.255.0",
                "network": "45.144.132.0",
                "bitmask": "24",
                "gateway": "45.144.132.254",
                "broadcast": "45.144.132.255"
            },
            "133": {
                "subnet": "255.255.255.0",
                "network": "45.144.133.0",
                "bitmask": "24",
                "gateway": "45.144.133.254",
                "broadcast": "45.144.133.255"
            },
            "135": {
                "subnet": "255.255.255.0",
                "network": "45.144.135.0",
                "bitmask": "24",
                "gateway": "45.144.135.254",
                "broadcast": "45.144.135.255"
            }
        }
    },
    "80": {
        "64": {
            "190": {
                "subnet": "255.255.255.0",
                "network": "80.64.190.0",
                "bitmask": "24",
                "gateway": "80.64.190.254",
                "broadcast": "80.64.190.255"
            }
        },
        "237": {
            "134": {
                "subnet": "255.255.255.224",
                "network": "80.237.134.192",
                "bitmask": "27",
                "gateway": "80.237.134.193",
                "broadcast": "80.237.134.223"
            }
        }
    },
    "87": {
        "255": {
            "44": {
                "subnet": "255.255.255.0",
                "network": "87.255.44.0",
                "bitmask": "24",
                "gateway": "87.255.44.254",
                "broadcast": "87.255.44.255"
            }
        }
    },
    "91": {
        "217": {
            "27": {
                "subnet": "255.255.255.0",
                "network": "91.217.27.0",
                "bitmask": "24",
                "gateway": "91.217.27.254",
                "broadcast": "91.217.27.255"
            }
        },
        "250": {
            "106": {
                "subnet": "255.255.255.224",
                "network": "91.250.106.0",
                "bitmask": "27",
                "gateway": "91.250.106.1",
                "broadcast": "91.250.106.31"
            },
            "107": {
                "subnet": "255.255.255.224",
                "network": "91.250.107.32",
                "bitmask": "27",
                "gateway": "91.250.107.33",
                "broadcast": "91.250.107.63"
            }
        }
    },
    "138": {
        "124": {
            "150": {
                "subnet": "255.255.255.0",
                "network": "138.124.150.0",
                "bitmask": "24",
                "gateway": "138.124.150.1",
                "broadcast": "138.124.150.255"
            },
            "151": {
                "subnet": "255.255.255.0",
                "network": "138.124.151.0",
                "bitmask": "24",
                "gateway": "138.124.151.1",
                "broadcast": "138.124.151.255"
            }
        }
    },
    "185": {
        "88": {
            "147": {
                "subnet": "255.255.255.0",
                "network": "185.88.147.0",
                "bitmask": "24",
                "gateway": "185.88.147.254",
                "broadcast": "185.88.147.255"
            }
        },
        "102": {
            "subnet": "255.255.252.0",
            "network": "185.102.92.0",
            "bitmask": "22",
            "gateway": "185.102.95.254",
            "broadcast": "185.102.95.255"
        },
        "201": {
            "144": {
                "subnet": "255.255.255.0",
                "network": "185.201.144.0",
                "bitmask": "24",
                "gateway": "185.201.144.254",
                "broadcast": "185.201.144.255"
            },
            "145": {
                "subnet": "255.255.255.0",
                "network": "185.201.145.0",
                "bitmask": "24",
                "gateway": "185.201.145.254",
                "broadcast": "185.201.145.255"
            },
            "146": {
                "subnet": "255.255.255.0",
                "network": "185.201.146.0",
                "bitmask": "24",
                "gateway": "185.201.146.254",
                "broadcast": "185.201.146.255"
            },
            "147": {
                "subnet": "255.255.255.0",
                "network": "185.201.147.0",
                "bitmask": "24",
                "gateway": "185.201.147.254",
                "broadcast": "185.201.147.255"
            }
        },
        "207": {
            "229": {
                "subnet": "255.255.255.0",
                "network": "185.207.229.0",
                "bitmask": "24",
                "gateway": "185.207.229.254",
                "broadcast": "185.207.229.255"
            }
        },
        "211": {
            "60": {
                "subnet": "255.255.255.0",
                "network": "185.211.60.0",
                "bitmask": "24",
                "gateway": "185.211.60.254",
                "broadcast": "185.211.60.255"
            }
        },
        "229": {
            "88": {
                "subnet": "255.255.255.0",
                "network": "185.229.88.0",
                "bitmask": "24",
                "gateway": "185.229.88.1",
                "broadcast": "185.229.88.255"
            }
        },
        "241": {
            "34": {
                "subnet": "255.255.255.0",
                "network": "185.241.34.0",
                "bitmask": "24",
                "gateway": "185.241.34.254",
                "broadcast": "185.241.34.255"
            }
        }
    },
    "193": {
        "163": {
            "15": {
                "subnet": "255.255.255.0",
                "network": "193.163.15.0",
                "bitmask": "24",
                "gateway": "193.163.15.254",
                "broadcast": "193.163.15.255"
            }
        },
        "203": {
            "253": {
                "subnet": "255.255.255.0",
                "network": "193.203.253.0",
                "bitmask": "24",
                "gateway": "193.203.253.1",
                "broadcast": "193.203.253.255"
            }
        }
    },
    "194": {
        "56": {
            "188": {
                "subnet": "255.255.255.0",
                "network": "194.56.188.0",
                "bitmask": "24",
                "gateway": "194.56.188.1",
                "broadcast": "194.56.188.255"
            },
            "191": {
                "subnet": "255.255.255.0",
                "network": "194.56.191.0",
                "bitmask": "24",
                "gateway": "194.56.191.1",
                "broadcast": "194.56.191.255"
            }
        }
    }
};