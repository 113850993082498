import { Injectable } from '@angular/core';
import { ipConfig } from './ip.config';

@Injectable({
  providedIn: 'root'
})
export class IpService {


  getIpConfig(ipv4: string) {
    const parts = ipv4.split('.');
    let config: any = { ...ipConfig };
    let networkAddress = '';
    parts.forEach(part => {
      if (config[part]) {
        config = config[part];

        if (networkAddress !== '') {
          networkAddress += '.';
        }

        networkAddress += part;
      }
    });

    while (networkAddress.split('.').length < 4) {
      networkAddress += '.x';
    }

    if (config['network']) {
      config['networkAddress'] = networkAddress;
      return config;
    }

    return false;
  }

  getIpV6Config(fakeGatewayAddress: string, fakeNetworkBitmask: string) {
    return {
      subnet: '',
      network: '',
      bitmask: fakeNetworkBitmask,
      gateway: fakeGatewayAddress,
      broadcast: ''
    };
  }
}
