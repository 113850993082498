import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as _ from 'lodash';

/**
 * provides an ansynchronous (not really...) implementation of localstorage
 * and also handles if localstorage is not available (uses fake storage instead)
 */

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private fallbackLocalStorage: Record<string, string | null> = {};
  private isLocalStorageAvailable = this._testLocalStorageAvailable();

  private subjects: Record<string, BehaviorSubject<string | null>> = {};

  /**
   * gets and returns an item from localstorage, but waits 500ms until it is there
   */
  getItem$(_key: string): Observable<string | null> {
    if (this.subjects[_key] === undefined) {
      this.subjects[_key] = new BehaviorSubject(this.getItem(_key));
    }

    return this.subjects[_key].pipe(
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
      filter(value => value !== undefined)
    );
  }

  /**
   * get an return item from localstorage or the optional default value
   */
  getItem(key: string, defaultValue?: string): string | null {
    const val = this._getItem(key);
    if (val === null && defaultValue !== undefined) {
      return defaultValue;
    }
    return val;
  }

  /**
   * stores an item into localstorage
   */
  setItem(_key: string, _value: string) {
    this._setItem(_key, _value);

    if (this.subjects[_key] !== undefined) {
      this.subjects[_key].next(_value);
    }
  }

  /**
   * removes an item from localstorage
   */
  removeItem(_key: string) {
    this._removeItem(_key);
  }

  /**
   * clears the localstorage
   */
  clear() {
    this._clear();
  }

  private _getItem(key: string): string | null {
    if (this.isLocalStorageAvailable) {
      return window.localStorage.getItem(key);
    } else {
      return _.get(this.fallbackLocalStorage, key, null);
    }
  }

  private _setItem(key: string, value: string): void {
    if (this.isLocalStorageAvailable) {
      window.localStorage.setItem(key, value);
    } else {
      this.fallbackLocalStorage[key] = value;
    }
  }

  private _removeItem(key: string) {
    if (this.isLocalStorageAvailable) {
      window.localStorage.removeItem(key);
    } else {
      this.fallbackLocalStorage[key] = null;
      delete this.fallbackLocalStorage[key];
    }
  }

  private _clear() {
    if (this.isLocalStorageAvailable) {
      window.localStorage.clear();
    } else {
      this.fallbackLocalStorage = {};
    }
  }

  private _testLocalStorageAvailable() {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
