import { Injectable } from '@angular/core';
import { Netmask } from 'netmask';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor() { }

  /**
   * calculates the subnet mask from bitcount (bitcount = the number behind the slash in cidr)
   * @credits: https://stackoverflow.com/questions/27261281/function-for-subnet-mask
   */
  CIDR2netmask(bitCount: number): string {
    var mask: number[] = [];
    for (var i = 0; i < 4; i++) {
      var n = Math.min(bitCount, 8);
      mask.push(256 - Math.pow(2, 8 - n));
      bitCount -= n;
    }
    return mask.join('.');
  }

  /**
   * returns IP range as string for a cidr (e.g. "192.168.0.1 - 192.168.0.254")
   */
  getIpV4RangeStringForNetwork(network: string, netmask = 24, onlyHosts = true): string {
    if (this.isIpv4NetworkAddressValid(network, netmask)) {
      const block = new Netmask(network + '/' + netmask);
      if (onlyHosts || block.broadcast === undefined) {
        return block.first + ' - ' + block.last;
      }
      return block.base + ' - ' + block.broadcast;
    }
    return '';
  }

  /**
   * returns an array of possible IP adresses for a cidr
   */
  getIpV4AddressesForNetwork(network: string, netmask = 24, excludedCIDRs?: string[]): { label: string; value: string }[] {
    const possibleIps: { label: string; value: string }[] = [];
    const excludedHosts: string[] = []; // direct host IP addresses
    const excludedRanges: Netmask[] = [];

    if (excludedCIDRs?.length !== undefined && excludedCIDRs?.length > 0) {
      excludedCIDRs.forEach(cidr => {
        if (typeof cidr === 'string') {
          if (cidr.match(/^([0-9]{1,3}\.){3}[0-9]{1,3}\/[0-9]+$/)) {
            excludedRanges.push(new Netmask(cidr));
          } else if (cidr.match(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
            excludedHosts.push(cidr);
          }
        }
      });
    }

    if (this.isIpv4NetworkAddressValid(network, netmask)) {
      const block = new Netmask(network + '/' + netmask);
      block.forEach((ip, long, index) => {
        if (excludedHosts.indexOf(ip) >= 0) {
          return;
        }
        let found = false;
        excludedRanges.forEach(range => {
          found = range.contains(ip);
          if (found) {
            return false;
          }
        });
        if (found) {
          return;
        }

        possibleIps.push({
          label: ip,
          value: ip
        });
      });
    }

    return possibleIps;
  }

  /**
   * validates an IPv4 Network address
   */
  isIpv4NetworkAddressValid(networkAddress: string, netmask = 24) {
    let valid = false;
    if (networkAddress && networkAddress.match(/^([0-9]{1,3}\.){3}[0-9]+$/)) {
      try {
        const block = new Netmask(networkAddress + '/' + netmask);
        valid = true;
      } catch (e) {}
    }
    return valid;
  }

  /**
   * returns the internal reserved ip for the server itself
   */
  getInternalReservedIp(network: string, netmask = 24): string {
    if (this.isIpv4NetworkAddressValid(network, netmask)) {
      const block = new Netmask(network + '/' + netmask);
      return block.last;
    }
    return '';
  }
}
