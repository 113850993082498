import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DateTimeService } from './datetime.service';

export interface IMaintenance {
  message: string;
  name: string;
  scheduled_at: string;
  scheduled_today: boolean;
  status: number;
  statusClass: string;
  time_left: string;
}

export interface IPartnerNotification {
  maintenance_id: string;
  message: string;
  scheduled_at: string;
  hide_after: string;
  headline: string;
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  partnerUuid: string | undefined;
  ready = false;

  public maintenances$: BehaviorSubject<IMaintenance[]> = new BehaviorSubject([] as IMaintenance[]);

  emptyMaintenance: IMaintenance = {
    message: '',
    name: 'Maintenance',
    scheduled_at: '',
    scheduled_today: false,
    status: 0,
    statusClass: 'scheduled',
    time_left: ''
  };


  maintenances: IMaintenance[] = [];

  constructor(private httpClient: HttpClient, private dateTime: DateTimeService) { }

  setup(_partner_uuid: string) {
    this.partnerUuid = _partner_uuid;
    this.load();
  }

  load() {
    this.httpClient.get<IPartnerNotification[]>(`${location.protocol}//${location.host}/igstatic/notifications/` + this.partnerUuid + '.json?t=' + Date.now()).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 403 || e.status === 404) {
          return of([] as IPartnerNotification[]);
        }
        throw 'Request error ' + e.message;
      }),
    ).subscribe(res => {
      this.renderData(res);
      this.ready = true;
    });
  }

  renderData(_data: IPartnerNotification[]) {
    this.ready = true;
    if (_data && _data.length > 0) {
      _data.forEach(notice => {
        if (notice.message && notice.scheduled_at) {
          const _node = { ...this.emptyMaintenance };
          _node.message = notice.message;
          _node.scheduled_at = notice.scheduled_at;
          _node.name = notice.headline || _node.name;

          _node.scheduled_today = this.dateTime.isAfter(new Date(notice.scheduled_at), this.dateTime.getStartOf('day', new Date)) && this.dateTime.isBefore(new Date(notice.scheduled_at), this.dateTime.getEndOf('day', new Date));
          _node.time_left = this.dateTime.fromNow(new Date(notice.scheduled_at));

          if (notice.hide_after) {
            if (new Date(notice.hide_after) > new Date()) {
              this.maintenances.push(_node);
            }
          } else {
            this.maintenances.push(_node);
          }
        }
      });
    }

    this.maintenances$.next(this.maintenances);
  }

  public getMaintenances() {
    return JSON.parse(JSON.stringify(this.maintenances));
  }
}
