import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  readonly DEPENDENCYS: {
    name: string;
    feature_to_disable: string;
    feature_to_listen_to: string[];
  }[] = [
      {
        name: 'Disable Kubernetes when Related Feature is Toggled of',
        feature_to_disable: 'kubernetes',
        feature_to_listen_to: [
          'publicnetwork',
          'loadbalancer',
          'manage_ip_addr_fake',
          'ipv4_add',
          'ipv4_update',
          'ipv4_remove',
          'ipv6_add',
          'ipv6_update',
          'ipv6_remove',
          'snapshot_schedule'
        ],
      },
      {
        name: 'Disable Loadbalancer if you disable IPs',
        feature_to_disable: 'loadbalancer',
        feature_to_listen_to: [
          'publicnetwork',
          'manage_ip_addr_fake',
          'ipv4_add',
          'ipv4_update',
          'ipv4_remove',
          'ipv6_add',
          'ipv6_update',
          'ipv6_remove',
          'snapshot_schedule'
        ],
      },
      {
        name: 'Disable PaaS if you disable Snapshot Scheduler',
        feature_to_disable: 'paas',
        feature_to_listen_to: ['snapshot_schedule'],
      },
      /**
       * This feature dependencies were defined in GD-13965
       */
      {
        name: 'Disable Snapshot export if objectstorage is disabled',
        feature_to_disable: 'snapshot_s3export',
        feature_to_listen_to: [
          'object_storage'
        ],
      },
      {
        name: 'Disable Marketplace template create if objectstorage is disabled',
        feature_to_disable: 'application_provide',
        feature_to_listen_to: [
          'object_storage'
        ],
      },
      {
        name: 'Disable Marketplace publish if we cannot create templates',
        feature_to_disable: 'application_provide_public',
        feature_to_listen_to: [
          'application_provide'
        ]
      },
      {
        name: 'Disable Marketplace template if we cannot export snapshot',
        feature_to_disable: 'application_provide',
        feature_to_listen_to: [
          'snapshot_s3export'
        ]
      },
      {
        name: 'Disable rocket storage if we dont have backup center',
        feature_to_disable: 'local_storage',
        feature_to_listen_to: [
          'backup_center'
        ]
      },
      // {
      //   name: 'Disable internet if one of the ip management features is turned off',
      //   feature_to_disable: 'publicnetwork',
      //   feature_to_listen_to: [
      //     'ipv4_add',
      //     'ipv4_update',
      //     'ipv4_remove',
      //     'ipv6_add',
      //     'ipv6_update',
      //     'ipv6_remove'
      //   ]
      // },
    ];
}
